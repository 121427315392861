import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

const Phrase = styled(ParseContent)`
  padding-left: 30px;

  & h2 {
    font-size: ${(props) => props.theme.font.size['75']};
    line-height: ${(props) => props.theme.font.size['75']};
    color: ${(props) => props.theme.color.text.grey};
  }

  @media screen and (max-width: 991px) {
    padding-left: 0;

    & h2 {
      font-size: ${(props) => props.theme.font.size[60]};
      line-height: ${(props) => props.theme.font.size[60]};
      color: ${(props) => props.theme.color.text.grey};
    }
  }
`

const Content = styled(ParseContent)`
  padding-right: 0px;
`

const StyledTextWithPhrase = styled.div`
  padding: 100px 0 100px 0;

  @media screen and (max-width: 991px) {
    padding: 50px 15px 50px 15px;
  }

  @media screen and (max-width: 576px) {
    padding: 20px 15px;
  }
`

interface TextWithPhraseProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_TextWithPhrase
}

const TextWithPhrase: React.FC<TextWithPhraseProps> = ({ fields }) => (
  <StyledTextWithPhrase className="container">
    <div className="row">
      <div className="col-lg-6">
        <Content content={fields.description} />
      </div>
      <div className="col-lg-6 pt-lg-0 pt-4">
        <Phrase content={fields.phrase} />
      </div>
    </div>
  </StyledTextWithPhrase>
)

export default TextWithPhrase
